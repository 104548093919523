<template>
  <CompanyInformation />
</template>
<script>
import CompanyInformation from "../../molecules/CompanyInformation.vue";
export default {
  name: "CompanyInfo",
  components: { CompanyInformation },
};
</script>
